<script setup lang="ts">
const { mode = 'both', direction = 'vertical' } = defineProps<{
  mode?: 'both' | 'text' | 'logo'
  direction?: 'horizontal' | 'vertical'
}>()

const viewbox = direction === 'vertical' ? mode === 'both' ? '0 0 12.5 17' : mode === 'logo' ? '0 0 12.5 12.5' : '0 0 12.5 17' : mode === 'both' ? '0 0 26 12.5' : mode === 'logo' ? '0 0 12.5 12.5' : '0 0 12.5 12.5'
</script>

<template>
  <svg
    :viewBox="viewbox" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve" xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g style="transform:translateX(0.5px) translateY(0.4px)">
      <rect
        v-if="mode === 'both'"
        x="1.09" y="1" width="8.204" height="16"
      />
      <g v-if="mode === 'both' || mode === 'logo'">
        <path
          d="M9.835,7.188c-0.061,0.418 -0.179,0.829 -0.354,1.217c-1.381,3.111 -5.571,3.751 -7.831,1.171c-2.25,-2.589 -1.07,-6.632 2.205,-7.611c0.642,-0.194 1.318,-0.237 1.975,-0.145l0.248,-0.422c0.055,-0.101 0.201,-0.174 0.201,-0.174c0.425,-0.01 0.796,0.068 1.111,0.186c0.333,-0.36 1.019,-1.062 1.305,-1.055c0.384,0.009 0.484,0.631 0.64,0.668c0.11,0.036 0.22,-0 0.293,-0.083l0.192,0.265c-0,0 -0.165,0.247 -0.421,0.22c-0.476,-0.046 -0.403,-0.549 -0.723,-0.613c-0.209,-0.043 -0.61,0.466 -0.849,0.803c0.531,0.303 0.813,0.67 0.813,0.67c0.128,0.165 0.091,0.348 0.091,0.348l-0.236,0.494c0.082,0.082 0.161,0.167 0.236,0.256l-0,-0c0.105,0.121 0.204,0.248 0.297,0.379c0.206,0.289 0.376,0.601 0.509,0.928c0.321,0.778 0.439,1.643 0.298,2.498Z"
          style="fill:none;stroke:#fafafa;stroke-width:1.5px;"
        />
        <path
          d="M7.222,1.617c0.036,0.083 0.1,0.147 0.164,0.201c0.073,0.046 0.165,0.074 0.247,0.083c0,-0 0.732,-1.153 1.043,-1.089c0.32,0.064 0.247,0.567 0.723,0.613c0.256,0.027 0.421,-0.22 0.421,-0.22l-0.192,-0.265c-0.073,0.083 -0.183,0.119 -0.293,0.083c-0.156,-0.037 -0.256,-0.659 -0.64,-0.668c-0.385,-0.009 -1.492,1.262 -1.492,1.262l0.019,0Z"
          style="fill-rule:nonzero;"
        />
        <path

          d="M8.438,3.246l0.293,-0.613c0,-0 0.037,-0.183 -0.091,-0.348c-0,0 -0.842,-1.098 -2.361,-1.061c0,-0 -0.146,0.073 -0.201,0.174l-0.366,0.622c0,-0 1.647,0.082 2.726,1.226Z"
          style="fill-rule:nonzero;stroke:#000;stroke-width:0.18px;stroke-linejoin:miter;stroke-miterlimit:10;"
        />
        <path
          d="M1.341,4.852c0.731,-2.263 3.142,-3.513 5.381,-2.79c2.239,0.724 3.463,3.148 2.732,5.411c-0.731,2.263 -3.142,3.513 -5.381,2.79c-2.239,-0.723 -3.463,-3.148 -2.732,-5.411Z"
          style="fill:#d65f33;"
        />
        <path
          d="M6.718,2.056c2.242,0.686 3.632,3.193 3.038,5.508c-0.43,1.839 -2.077,3.312 -3.971,3.495c-1.281,0.137 -2.625,-0.284 -3.577,-1.153c-1.436,-1.254 -1.912,-3.422 -1.18,-5.16c0.869,-2.223 3.467,-3.44 5.69,-2.69Zm0,0c-2.223,-0.704 -4.556,0.622 -5.077,2.891c-0.485,1.986 0.714,4.108 2.626,4.703c1.893,0.64 4.107,-0.375 4.885,-2.278c0.897,-2.141 -0.22,-4.584 -2.434,-5.316Z"
          style="fill-rule:nonzero;"
        />
        <path
          d="M8.722,3.383c1.208,1.336 1.5,3.376 0.759,5.022c-1.381,3.111 -5.571,3.751 -7.831,1.171c-2.25,-2.589 -1.07,-6.632 2.205,-7.611c1.729,-0.522 3.705,0.046 4.876,1.418l-0.009,-0Zm-0.037,0.027c-1.152,-1.335 -3.119,-1.866 -4.784,-1.281c-2.983,1.016 -3.989,4.767 -1.903,7.146c2.077,2.378 5.928,1.875 7.328,-0.952c0.805,-1.573 0.54,-3.595 -0.631,-4.913l-0.01,0Z"
          style="fill-rule:nonzero;"
        />
        <path
          d="M7.405,3.758c0.228,-0.348 0.686,-0.275 1.015,0.155c0.329,0.43 0.421,1.071 0.192,1.419c-0.228,0.347 -0.686,0.274 -1.015,-0.156c-0.33,-0.43 -0.421,-1.07 -0.192,-1.418Z"
          style="fill:#ecd5cd;fill-rule:nonzero;"
        />
        <path
          d="M8.722,6.503c-0.073,0.155 -0.21,0.118 -0.311,-0.074c-0.101,-0.192 -0.128,-0.466 -0.055,-0.622c0.073,-0.155 0.21,-0.119 0.311,0.073c0.101,0.193 0.128,0.476 0.055,0.623Z"
          style="fill:#ecd5cd;fill-rule:nonzero;"
        />
      </g>
      <g v-if="mode === 'both' || mode === 'text'" :class="{ 'translate-x-[10px] -translate-y-[14px] scale-150': direction === 'horizontal' }">
        <path
          d="M4.834,15.742l-3.744,-0l0,-3.353l0.887,0l-0,2.619l1.32,0l0,-2.619l1.416,0c0.249,0 0.458,0.035 0.628,0.103c0.169,0.069 0.297,0.166 0.384,0.292c0.034,0.049 0.061,0.101 0.082,0.157l-0,-0.552l1.022,0l0.704,1.899l0.035,-0l0.703,-1.899l1.023,0l-0,3.353l-0.804,-0l0,-1.939l-0.024,0l-0.673,1.912l-0.485,0l-0.674,-1.925l-0.023,0l-0,1.952l-0.804,-0l-0,-0.409c-0.09,0.119 -0.21,0.214 -0.36,0.286c-0.172,0.082 -0.376,0.123 -0.613,0.123Zm-0.65,-1.997l0.363,-0c0.078,-0 0.146,-0.013 0.206,-0.038c0.059,-0.025 0.106,-0.061 0.139,-0.109c0.034,-0.047 0.051,-0.106 0.051,-0.174c-0,-0.104 -0.036,-0.183 -0.109,-0.238c-0.072,-0.056 -0.163,-0.083 -0.274,-0.083l-0.376,-0l-0,0.642Zm1.623,-0.249c-0.009,0.024 -0.02,0.046 -0.031,0.069c-0.053,0.101 -0.126,0.186 -0.221,0.254c-0.095,0.068 -0.205,0.115 -0.332,0.142l0,0.032c0.141,0.006 0.268,0.042 0.383,0.109c0.078,0.046 0.145,0.102 0.201,0.171l-0,-0.777Zm-1.623,1.519l0.414,-0c0.149,-0 0.261,-0.029 0.335,-0.086c0.075,-0.057 0.112,-0.142 0.112,-0.255c-0,-0.078 -0.018,-0.145 -0.053,-0.199c-0.035,-0.055 -0.085,-0.096 -0.149,-0.125c-0.064,-0.028 -0.142,-0.042 -0.232,-0.042l-0.427,-0l-0,0.707Z"
          style="fill:#fafafa;"
        />
        <path
          d="M5.319,11.973l0,-0.071l1.85,-0l0.381,1.03l0.382,-1.03l1.849,-0l0,4.327l-1.778,0l-0,-0.026l-0.905,-0l0,0.026l-1.779,0l0,-0.053c-0.148,0.035 -0.309,0.053 -0.485,0.053l-4.231,0l-0,-4.327l1.861,-0c0,-0 0,2.619 0,2.619c0,-0 0.346,-0 0.346,-0l-0,-2.619l1.903,-0c0.228,-0 0.43,0.025 0.606,0.071Zm1.314,1.817l0.001,0.464l0.058,-0.057l0.056,-0.08l0.56,1.598l0.485,0l0.673,-1.912l0.024,0l0,0.471l0,1.468l0.804,-0l-0,-3.353l-1.023,0l-0.703,1.899l-0.035,-0l-0.704,-1.899l-1.022,0l-0,0.552c-0.021,-0.056 -0.048,-0.108 -0.082,-0.157c-0.087,-0.126 -0.215,-0.223 -0.384,-0.292c-0.17,-0.068 -0.379,-0.103 -0.628,-0.103l-1.416,0l0,2.619l-1.32,0l-0,-2.619l-0.887,0l0,3.353l3.744,-0c0.237,-0 0.441,-0.041 0.613,-0.123c0.15,-0.072 0.27,-0.167 0.36,-0.286l-0,0.409l0.804,-0l-0,-1.952l0.022,0Zm-2.449,1.225l-0,-0.707l0.427,-0c0.09,-0 0.168,0.014 0.232,0.042c0.064,0.029 0.114,0.07 0.149,0.125c0.035,0.054 0.053,0.121 0.053,0.199c-0,0.113 -0.037,0.198 -0.112,0.255c-0.074,0.057 -0.186,0.086 -0.335,0.086l-0.414,-0Zm-0,-1.27l-0,-0.642l0.376,-0c0.111,-0 0.202,0.027 0.274,0.083c0.073,0.055 0.109,0.134 0.109,0.238c-0,0.068 -0.017,0.127 -0.051,0.174c-0.033,0.048 -0.08,0.084 -0.139,0.109c-0.06,0.025 -0.128,0.038 -0.206,0.038l-0.363,-0Zm1.623,-0.249l-0,0.777c-0.056,-0.069 -0.123,-0.125 -0.201,-0.171c-0.115,-0.067 -0.242,-0.103 -0.383,-0.109l0,-0.032c0.127,-0.027 0.237,-0.074 0.332,-0.142c0.095,-0.068 0.168,-0.153 0.221,-0.254c0.011,-0.023 0.022,-0.045 0.031,-0.069Z"
        />
      </g>
    </g>
  </svg>
</template>
